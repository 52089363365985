"use client";
import { Spin } from 'antd';
export default function Loading() {
    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100vw"
        }}>
            <Spin spinning={true} />
        </div>
    )
}
